import React, {useCallback, useState, useEffect} from 'react';
import {SERVER_URL} from '../../../constants';
import {dateAsWords} from '../../../util/DateAsWords';
import RequestForProposalRiskStatusWidget from './RequestForProposalRiskStatusWidget';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  gridExpandedSortedRowEntriesSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import QuotesCountCell from './QuotesCountCell';
import AssignToWidget from './AssignToWidget';

const CustomRfpsToolbar = (props: $TSFixMe) => {
  const apiRef = useGridApiContext();

  const [hasRowsSelected, setHasRowsSelected] = useState(false);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowSelectionChange', (params) => {
      const selection = apiRef.current.getSelectedRows();
      setHasRowsSelected(selection.size > 0);
    });
  }, [apiRef]);

  const assignProjectsToUser = (userId: $TSFixMe) => {
    const selection = apiRef.current.getSelectedRows();
    const rfpIds: $TSFixMe = [];

    selection.forEach((rfp) => rfpIds.push(rfp.id));

    const url = `${SERVER_URL}v1/rfps/assign`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({rfpIds: rfpIds, userId: userId}),
    })
      .then(() => {
        props.getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {hasRowsSelected && (
        <>
          <AssignToWidget assignProject={assignProjectsToUser} />
        </>
      )}
      <GridToolbarQuickFilter
        style={{position: 'fixed', right: 50}}
        debounceMs={props.quickFilterProps.debounceMs}
      />
    </GridToolbarContainer>
  );
};

const RequestForProposalsTable = (props: $TSFixMe) => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      window.open(
        `${window.location.origin}/myRfps/${params?.row?.id}`,
        '_blank',
      );
    });
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('cellClick', (params) => {
      switch (params.field) {
        case 'firstName':
          window.open(
            `${window.location.origin}/users/${params?.row?.userId}`,
            '_blank',
          );
          break;
        case 'email':
          window.open(`mailto:${params?.row?.email}`, '_blank');
          break;
        case 'phone':
          window.open(`tel:${params?.row?.phone}`, '_blank');
          break;
      }
    });
  }, [apiRef]);

  const getData = useCallback(() => {
    setLoading(true);
    fetch(
      `${SERVER_URL}v1/rfps?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&filter=${props.filter}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {headerName: 'UID', field: 'uid'},
    {headerName: 'Title', field: 'title', width: 170},
    {
      headerName: 'Created',
      field: 'createdDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {
      headerName: 'State',
      field: 'state',
      type: 'singleSelect',
      valueOptions: ['DRAFT', 'OPEN', 'FUNDED', 'COMPLETED', 'CANCELLED'],
    },
    {
      headerName: '# Of Quotes',
      field: 'proposalsCount',
      renderCell: (params: $TSFixMe) => <QuotesCountCell params={params} />,
      type: 'number',
      width: 120,
    },
    {
      headerName: 'Scope',
      field: 'size',
      type: 'singleSelect',
      valueOptions: ['XS', 'SM', 'M', 'L', 'XL'],
      width: 60,
    },
    {
      headerName: 'Risk Status',
      field: 'riskStatus',
      renderCell: (params: $TSFixMe) => (
        <div>
          <RequestForProposalRiskStatusWidget
            small={true}
            riskStatus={params?.row?.riskStatus}
          />
        </div>
      ),
      width: 170,
    },
    {
      headerName: 'User',
      field: 'firstName',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="view user profile"
            component="span"
            style={{marginRight: 8}}>
            <OpenInNewIcon style={{fontSize: 17}} />
          </IconButton>
          {`${params?.row?.firstName || ''} ${params?.row?.lastName || ''}`}
        </span>
      ),
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        `${row?.firstName?.toUpperCase() || ''} ${
          row?.lastName?.toUpperCase() || ''
        }` || 'N/A',
      width: 175,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="email user"
            component="span"
            style={{marginRight: 8}}>
            <EmailIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.email || ''}
        </span>
      ),
      width: 250,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {params.row.phone || ''}
        </span>
      ),
      width: 140,
    },
    {
      headerName: 'Target',
      field: 'deadlineDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {
      headerName: 'Street',
      field: 'street',
      width: 160,
    },
    {
      headerName: 'City',
      field: 'city',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row?.city?.toUpperCase(),
      width: 100,
    },
    {
      headerName: 'Province',
      field: 'province',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.province?.toUpperCase(),
      width: 100,
    },
    {
      headerName: 'Postal',
      field: 'postal',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.postal?.toUpperCase(),
      width: 120,
    },
    {
      headerName: 'Type',
      field: 'type',
      type: 'singleSelect',
      valueOptions: ['DEFAULT', 'PROMOTED', 'BIDMII_DIRECT', 'REQUESTED'],
    },
    {
      headerName: 'Calls Enabled',
      field: 'approvedForCalls',
      type: 'boolean',
    },
    {
      headerName: 'Assigned To',
      field: 'assignedTo',
      width: 120,
    },
    {
      headerName: 'Reason',
      field: 'cancellationReason',
      width: 300,
    },
    {
      headerName: 'Rating',
      field: 'rating',
      width: 300,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/myRfps/${params.id}`,
              '_blank',
            );
          }}
          label="View Project"
        />,
      ],
      width: 100,
    },
  ];

  const onFilterModelChange = () => {
    //const r = apiRef.current;
    const rm = gridExpandedSortedRowEntriesSelector(apiRef);
    const visibleRows = rm.length;
    setRowCount(visibleRows);
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 80,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                riskStatus: false,
                street: false,
                postal: false,
                province: false,
                cancellationReason: false,
                assignedTo: false,
                rating: false,
                phone: false,
                deadlineDate: false,
                size: false,
              },
            },
          }}
          slots={{
            toolbar: CustomRfpsToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 750},
              getData: getData,
            },
          }}
          filterDebounceMs={750}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          onFilterModelChange={onFilterModelChange}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default RequestForProposalsTable;
