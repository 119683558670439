import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro';
import parser from 'html-react-parser';
import {Box} from '@material-ui/core';
import PayoutReport from './PayoutReport';
import Button from '@material-ui/core/Button';
import {SERVER_URL} from '../../../constants';

export default function QuotePaymentScheduleTable(props: $TSFixMe) {
  const [rows, setRows] = useState<$TSFixMe>([]);

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Stage',
      field: 'name',
      type: 'string',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => {
        if (!row) {
          return 'N/A';
        }
        return `${row?.isAddon ? 'Addon - ' : ''} ${row?.name}`;
      },
    },
    {headerName: 'State', field: 'state', type: 'string'},
    {
      headerName: 'Percent',
      field: 'payoutPercent',
      type: 'number',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => {
        if (row) {
          return `${row?.payoutPercent * 100} %`;
        } else {
          return '';
        }
      },
    },
    {headerName: 'Invoice', field: 'invoiceAmount', type: 'number'},
    {headerName: 'Contingency', field: 'contingencyAmount', type: 'number'},
    {headerName: 'Tax', field: 'tax', type: 'number'},
    {headerName: 'Total', field: 'total', type: 'number'},
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <PayoutReport
          key="QuotePaymentScheduleTable_actions_0"
          proposal={props.proposal}
          stage={params.row}
          isStage
        />,
      ],
      width: 100,
    },
  ];

  const approveProposalStage = (row: any) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/proposals/stages/${row.uid}/approve`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then(() => {
        props.updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resendReceipt = (row: any) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/proposals/stages/${row.id}/resend-receipt`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then(() => {
        props.updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const stageDetailView = (row: $TSFixMe) => {
    return (
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}>
        <Box p={2}>
          <Box mb={2}>
            <Typography>
              Time Estimate:{' '}
              {`${row.timeToCompleteAmount} ${row.timeToCompleteUnit}`}
            </Typography>
          </Box>
          {props.requestForProposal.state === 'COMPLETE' && (
            <Box mb={2}>
              <PayoutReport proposal={props.proposal} stage={row} />
            </Box>
          )}
          {row.state === 'APPROVAL_PENDING' && (
            <Box mb={2}>
              <Button
                onClick={() => approveProposalStage(row)}
                variant={'contained'}
                color={'secondary'}>
                APPROVE STAGE
              </Button>
            </Box>
          )}
          {['FUNDED', 'COMPLETE'].includes(row.state) && (
            <Box mb={2}>
              <Button
                onClick={() => resendReceipt(row)}
                variant={'contained'}
                color={'secondary'}>
                RESEND RECEIPT
              </Button>
            </Box>
          )}
          <Box style={{maxWidth: window.outerWidth > 600 ? '70%' : '100%'}}>
            {parser(row.description || '')}
          </Box>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    const stages = props.proposal.stages;
    const addons = props.proposal.addons
      .map((addon: $TSFixMe) => addon.stages)
      .flat()
      .map((addon: $TSFixMe) => ({...addon, isAddon: true}));
    setRows([...stages, ...addons]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box style={{height: 550}}>
        <DataGridPro
          rowHeight={50}
          getDetailPanelContent={({row}) => stageDetailView(row)}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          rows={rows}
          columns={columns}
        />
      </Box>
    </>
  );
}
